import type { HeadersFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { isAuthorized } from "shared/utils/basicAuth";
import "./tailwind.css";

import { isRouteErrorResponse, useRouteError } from "@remix-run/react";

export const headers: HeadersFunction = () => ({
  "WWW-Authenticate": "Basic",
});

// export async function loader({ request }: LoaderFunctionArgs) {
//   if (process.env.AUTH_ENABLED === "true") {
//     if (!isAuthorized(request)) {
//       return json({ authorized: false }, { status: 401 });
//     }
//   }
//   return json({
//     authorized: process.env.AUTH_ENABLED !== "true" || true,
//   });
// }

export function Layout({ children }: { children: React.ReactNode }) {
  // const { authorized = true } = useLoaderData<typeof loader>();

  // if (!authorized) {
  //   return <h1 className="text-3xl font-bold">🔐</h1>;
  // }

  return (
    <html lang="ja" className="h-full bg-[#fafaf6]">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex,nofollow" />
        <Meta />
        <Links />
      </head>
      <body className="h-full font-lp">
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return <h1>Unknown Error</h1>;
  }
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
